'use client'

import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import { isDev } from '@lyra/web/constants/env'
import { WalletType } from '@lyra/web/constants/wallet'
import AuthErrorModal from '@lyra/web/containers/common/AuthErrorModal'
import TermsOfUseModal from '@lyra/web/containers/common/TermsOfUseModal'
import WaasDeprecationModal from '@lyra/web/containers/common/WaasDeprecationModal'
import { recordSpindlEvent } from '@lyra/web/utils/spindl'
import {
  PasskeyWithMetadata,
  useLinkAccount,
  useLogin,
  useLogout,
  usePrivy,
  User,
  useWallets,
  WalletWithMetadata,
} from '@privy-io/react-auth'
import { useSetActiveWallet } from '@privy-io/wagmi'
import spindl from '@spindl-xyz/attribution'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Address } from 'viem'
import { useAccount } from 'wagmi'

import { Auth, LinkableSocial, NO_AUTH } from '../../constants/auth'
import emptyFunction from '../../utils/emptyFunction'
import WaasProvider from './WaasProvider'

// Poll auth every 5 mins
const AUTH_POLLING_INTERVAL_MS = SECONDS_IN_MINUTE * 5 * 1000

type Props = {
  auth: Auth
  children?: React.ReactNode
}

export type AuthContext = {
  privy: {
    isReady: boolean
    isCreating: boolean
    user?: User | null
    hasPasskey?: boolean
    isDiscordLinked?: boolean
    isTwitterLinked?: boolean
  }
  error?: string
  isAuthenticating: boolean
  mutate: (accessToken?: string) => Promise<Auth>
  login: () => void
  createSession: (walletType: WalletType) => Promise<Auth>
  logout: () => Promise<void>
  acknowledgeTerms: () => Promise<Auth>
  setUsername: (username?: string) => Promise<Auth>
  linkOrUpdateEmail: () => void
  linkGoogle: () => void
  linkPasskey: () => void
  unlinkPasskey: () => void
  linkSocial: (social: LinkableSocial) => void
  unlinkSocial: (social: LinkableSocial) => void
  createMockSessionDONOTUSE: (address: Address) => Promise<void>
  deleteMockSessionDONOTUSE: () => Promise<void>
} & Auth

export const AuthContext = React.createContext<AuthContext>({
  ...NO_AUTH,
  privy: {
    isReady: false,
    isCreating: false,
  },
  isAuthenticating: false,
  login: emptyFunction as any,
  createSession: emptyFunction as any,
  logout: emptyFunction as any,
  mutate: emptyFunction as any,
  acknowledgeTerms: emptyFunction as any,
  setUsername: emptyFunction as any,
  linkOrUpdateEmail: emptyFunction as any,
  linkGoogle: emptyFunction as any,
  linkPasskey: emptyFunction as any,
  unlinkPasskey: emptyFunction as any,
  linkSocial: emptyFunction as any,
  unlinkSocial: emptyFunction as any,
  createMockSessionDONOTUSE: emptyFunction as any,
  deleteMockSessionDONOTUSE: emptyFunction as any,
})

export default function AuthProvider({ auth: initialAuth, children }: Props) {
  const [auth, setAuth] = useState<Auth>(initialAuth)

  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const [error, setError] = useState<string>()

  const isAuthenticated = auth.isAuthenticated
  const ownerAddress = auth.user?.ownerAddress
  const address = auth.user?.address
  const acknowledgedTerms = !!auth.user?.acknowledgedTerms

  // track session changes on Spindl
  useEffect(() => {
    if (ownerAddress && address && process.env.NEXT_PUBLIC_SPINDL_API_KEY) {
      try {
        spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
        spindl.track('sp_proxy_address', {}, { address, customerUserId: ownerAddress })
      } catch (err) {
        console.warn('Spindl wallet attribution failed')
      }
    }
  }, [ownerAddress, address])

  const mutate = useCallback(async (accessToken?: string) => {
    const res = await fetch('/api/auth/me', {
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : undefined,
      cache: 'no-store',
    })
    if (res.ok) {
      const newAuth = (await res.json()) as Auth
      setAuth(newAuth)
      return newAuth
    } else {
      console.log('mutate failed', { error: res.status })
      setAuth(NO_AUTH)
      return NO_AUTH
    }
  }, [])

  const {
    ready: isPrivyReady,
    user: privyUser,
    linkEmail,
    updateEmail,
    linkPasskey,
    unlinkPasskey: _unlinkPasskey,
    getAccessToken,
    unlinkDiscord,
    unlinkTwitter,
    unlinkWallet,
  } = usePrivy()

  const privy: AuthContext['privy'] = useMemo(() => {
    return {
      isReady: isPrivyReady,
      // user has privy session but no wallet, prompt to create wallet
      isCreating: !!privyUser && !privyUser.wallet,
      user: privyUser,
      hasPasskey: privyUser
        ? !!privyUser.linkedAccounts.find((account) => account.type === 'passkey')
        : undefined,
      isDiscordLinked: privyUser
        ? !!privyUser.linkedAccounts.find((account) => account.type === 'discord_oauth')
        : undefined,
      isTwitterLinked: privyUser
        ? !!privyUser.linkedAccounts.find((account) => account.type === 'twitter_oauth')
        : undefined,
    }
  }, [isPrivyReady, privyUser])

  const unlinkPasskey = useCallback(async () => {
    const passkeyAccount = privyUser?.linkedAccounts.find(
      (account) => account.type === 'passkey'
    ) as PasskeyWithMetadata | undefined
    if (!passkeyAccount) {
      throw new Error('User has no passkey')
    }
    await _unlinkPasskey(passkeyAccount.credentialId)
  }, [_unlinkPasskey, privyUser])

  const { logout: _logout } = useLogout()

  const logout = useCallback(async () => {
    console.debug('privy: logout')

    if (privyUser) {
      // delete privy session locally
      await _logout()
    }

    // delete privy+lyra session
    await fetch('/api/auth/session', {
      method: 'DELETE',
    })

    // unset auth
    setAuth(NO_AUTH)
  }, [_logout, privyUser, isAuthenticated])

  const createSession = useCallback(
    async (walletType: WalletType) => {
      if (isAuthenticated) {
        throw new Error('Already authenticated')
      }

      try {
        setIsAuthenticating(true)

        const res = await fetch('/api/auth/session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            walletType,
          }),
        })
        if (!res.ok) {
          throw new Error(await res.text())
        }

        const auth = await mutate()
        if (!auth.isAuthenticated) {
          throw new Error('Not authenticated after mutation')
        }

        recordSpindlEvent(
          'sign-in',
          { walletType: auth.session.walletType },
          { address: auth.user.address, ownerAddress: auth.user.ownerAddress }
        )

        return auth
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
        } else {
          setError('Failed to sign in')
        }
        // attempt to logout (if they got that far)
        try {
          logout()
        } catch (_0) {
          // pass
        }
        throw error
      } finally {
        setIsAuthenticating(false)
      }
    },
    [isAuthenticated, logout, mutate]
  )

  const updateSession = useCallback(async () => {
    const res = await fetch('/api/auth/session', {
      method: 'PATCH',
    })
    if (!res.ok) {
      throw new Error(await res.text())
    }
    await mutate()
  }, [mutate])

  const { wallets } = useWallets()
  const { setActiveWallet } = useSetActiveWallet()

  const { address: connectedOwnerAddress } = useAccount()

  const { login: _login } = useLogin({
    async onComplete(user, isNewUser, wasAlreadyAuthenticated, loginMethod, loginAccount) {
      if (wasAlreadyAuthenticated || !loginMethod) {
        return
      }
      const linkedWalletAccounts = user.linkedAccounts.filter(
        (linkedAccount) => linkedAccount.type === 'wallet'
      ) as WalletWithMetadata[]
      if (
        // Check if multiple wallets connected
        linkedWalletAccounts.length > 1
      ) {
        const _latestConnectedWallet = linkedWalletAccounts[0]
        const latestConnectedWallet = linkedWalletAccounts.reduce((latest, wallet) => {
          if (
            !latest ||
            (wallet.latestVerifiedAt &&
              latest.latestVerifiedAt &&
              wallet.latestVerifiedAt > latest.latestVerifiedAt)
          ) {
            return wallet
          }
          return latest
        }, _latestConnectedWallet)
        await unlinkWallet(latestConnectedWallet.address)
        setError('A wallet is already connected. Refresh your browser before signing in again.')
        return
      }
      const userWallet = user.wallet
      if (!userWallet) {
        // user is creating an embedded wallet
        console.debug('privy: onLogin redirect to create wallet', {
          user,
          isNewUser,
          loginMethod,
          loginAccount,
        })
        // Disable new sign ups via Google/email
        setError(
          'Support for email and google sign in has been discontinued. Please connect with a self-hosted wallet like MetaMask, Coinbase Wallet or Rabby to continue.'
        )
      } else {
        // user is signing in
        const walletType = loginMethod === 'siwe' ? WalletType.External : WalletType.CoinbaseMPC
        console.debug('privy: onLogin', { user, isNewUser, loginMethod, walletType, loginAccount })
        await createSession(walletType)

        const targetWallet = wallets.find((wallet) => wallet.address === userWallet.address)
        if (targetWallet && targetWallet.address !== connectedOwnerAddress) {
          console.debug('privy: onLogin setActiveWallet', { targetWallet, connectedOwnerAddress })
          setActiveWallet(targetWallet)
        }
      }
    },
    onError: async (error) => {
      console.debug('privy: login error', { error })
      await mutate()
    },
  })

  const login = useCallback(async () => {
    if (isAuthenticated) {
      throw new Error('Already authenticated')
    }
    const accessToken = await getAccessToken()
    if (accessToken) {
      // user should already be authenticated, mutate
      console.debug('privy: access token exists, refreshing auth')
      const newAuth = await mutate(accessToken)
      if (!newAuth.isAuthenticated) {
        console.warn('privy: user has access token but no lyra-session, force logout')
        await logout()
        console.debug('privy: login')
        _login()
      }
    } else {
      console.debug('privy: login')
      _login()
    }
  }, [isAuthenticated, getAccessToken, mutate, logout, _login])

  const setUsername = useCallback(
    async (username?: string) => {
      if (!auth) {
        throw new Error('Not authenticated')
      }

      if (username) {
        const res = await fetch('/api/auth/username', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        })

        if (!res.ok) {
          throw new Error(await res.text())
        }
      } else {
        const res = await fetch('/api/auth/username', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!res.ok) {
          throw new Error(await res.text())
        }
      }

      return await mutate()
    },
    [auth, mutate]
  )

  const acknowledgeTerms = useCallback(async () => {
    const res = await fetch('/api/acknowledge-terms', { method: 'POST' })

    if (!res.ok) {
      throw new Error(await res.text())
    }

    return await mutate()
  }, [mutate])

  const { linkGoogle, linkTwitter, linkDiscord } = useLinkAccount({
    onSuccess: async (user, linkMethod, linkedAccount) => {
      console.debug('privy: linkAccount success', { user, linkMethod, linkedAccount })
      await updateSession()
    },
    onError: async (error) => {
      console.debug('privy: linkAccount error', { error })
      await mutate()
    },
  })

  const linkOrUpdateEmail = useCallback(() => {
    if (!privyUser?.email) {
      linkEmail()
    } else {
      updateEmail()
    }
  }, [linkEmail, privyUser?.email, updateEmail])

  // Poll auth
  useEffect(() => {
    const interval = setInterval(mutate, AUTH_POLLING_INTERVAL_MS)
    return () => {
      clearInterval(interval)
    }
  }, [mutate])

  const linkSocial = useCallback(
    async (social: LinkableSocial) => {
      switch (social) {
        case 'discord':
          linkDiscord()
          break
        case 'twitter':
          linkTwitter()
          break
      }
    },
    [linkDiscord, linkTwitter]
  )

  const unlinkSocial = useCallback(
    async (social: LinkableSocial) => {
      switch (social) {
        case 'discord':
          if (!privyUser?.discord?.subject) {
            console.debug('Tried to unlink discord from account that has no linked discord.')
            return
          }
          return await unlinkDiscord(privyUser.discord.subject)
        case 'twitter':
          if (!privyUser?.twitter?.subject) {
            console.debug('Tried to unlink twitter from account that has no linked twitter.')
            return
          }
          return await unlinkTwitter(privyUser.twitter.subject)
      }
    },
    [privyUser, unlinkDiscord, unlinkTwitter]
  )

  const createMockSessionDONOTUSE = useCallback(
    async (address: Address) => {
      if (!isDev) {
        throw new Error('Not in development')
      }

      const res = await fetch('/api/auth/mock', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      })

      if (!res.ok) {
        throw new Error('Failed to mock')
      }

      await mutate()
    },
    [mutate]
  )

  const deleteMockSessionDONOTUSE = useCallback(async () => {
    const res = await fetch('/api/auth/mock', {
      method: 'DELETE',
    })

    if (!res.ok) {
      throw new Error('Failed to unmock')
    }

    await mutate()
  }, [mutate])

  const value = useMemo(() => {
    return {
      ...auth,
      privy,
      error,
      isAuthenticating,
      login,
      createSession,
      logout,
      mutate,
      acknowledgeTerms,
      setUsername,
      linkGoogle,
      linkOrUpdateEmail,
      linkPasskey,
      unlinkPasskey,
      linkSocial,
      unlinkSocial,
      createMockSessionDONOTUSE,
      deleteMockSessionDONOTUSE,
    }
  }, [
    auth,
    privy,
    error,
    isAuthenticating,
    login,
    createSession,
    logout,
    mutate,
    acknowledgeTerms,
    setUsername,
    linkGoogle,
    linkOrUpdateEmail,
    linkPasskey,
    unlinkPasskey,
    linkSocial,
    unlinkSocial,
    createMockSessionDONOTUSE,
    deleteMockSessionDONOTUSE,
  ])

  return (
    <AuthContext.Provider value={value}>
      {!isAuthenticated && error ? (
        <AuthErrorModal
          error={error}
          onClose={() => {
            logout()
            setError(undefined)
          }}
        />
      ) : // Needs to be in TransactionProvider for WaasProvider context
      value.isAuthenticated && value.session.walletType === WalletType.CoinbaseMPC ? (
        // Only mount WaasProvider for users that need to export a wallet
        <WaasProvider>
          <WaasDeprecationModal />
        </WaasProvider>
      ) : isAuthenticated && !acknowledgedTerms ? (
        // SUPER IMPORTANT!! must enforce terms of use modal globally and as top priority
        <TermsOfUseModal />
      ) : null}
      {children}
    </AuthContext.Provider>
  )
}
