import { LightAccount } from '@alchemy/aa-accounts'
import { SmartAccountClient, SmartAccountSigner, SmartContractAccount } from '@alchemy/aa-core'
import { Account, Chain, Transport, WalletClient } from 'viem'

import { DepositNetwork } from './chains'
import { DepositTokenId, TokenId } from './tokens'

export type LyraWalletClient = WalletClient<Transport, Chain, Account>

export type LyraAccount = LightAccount<SmartAccountSigner, 'v1.1.0', '0.6.0'>
export type LyraAccountClient = SmartAccountClient<Transport, Chain, SmartContractAccount>

export enum WalletType {
  External = 'External',
  CoinbaseMPC = 'CoinbaseMPC',
}

export enum DeploymentState {
  UNDEFINED = '0x0',
  NOT_DEPLOYED = '0x1',
  DEPLOYED = '0x2',
}

export type LyraFundingBalances = Record<
  TokenId,
  {
    balance: number
    balanceBn: bigint
    spotPrice: number
    value: number
  }
>

export type DepositNetworkTokenBalances = Record<DepositTokenId, bigint>

export type DepositTokenBalances = Record<DepositNetwork, DepositNetworkTokenBalances>

export const getEmptyDepositBalancesForNetwork = (): Record<DepositTokenId, bigint> => {
  return Object.values(DepositTokenId).reduce(
    (acc, token) => {
      acc[token] = BigInt(0)
      return acc
    },
    {} as Record<DepositTokenId, bigint>
  )
}

export const EMPTY_DEPOSIT_TOKEN_BALANCES: DepositTokenBalances = {
  [DepositNetwork.Ethereum]: getEmptyDepositBalancesForNetwork(),
  [DepositNetwork.Arbitrum]: getEmptyDepositBalancesForNetwork(),
  [DepositNetwork.Optimism]: getEmptyDepositBalancesForNetwork(),
  [DepositNetwork.Base]: getEmptyDepositBalancesForNetwork(),
}

export const getEmptyTokenBalances = (): Record<TokenId, bigint> =>
  Object.values(TokenId).reduce(
    (acc, token) => {
      acc[token] = BigInt(0)
      return acc
    },
    {} as Record<TokenId, bigint>
  )
