import { Hash, Hex, PrivateKeyAccount } from 'viem'

import { CustomSessionClaims } from './session'
import { DeviceSessionKey } from './sessionKeys'
import { Subaccount } from './subaccount'
import { User } from './user'

export class KytError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export type TransactionDisabledReason =
  | 'kyt'
  | 'kyt-failed'
  | 'geoblocked'
  | 'terms'
  | 'invalid-auth-headers'
  | 'invalid-wallet'
  | 'eoa-reconnect'

export type AuthSession = CustomSessionClaims

// siwe = External, else = CoinbaseMpc

export type Auth =
  | {
      isAuthenticated: true
      isMaybeAuthenticated: false
      user: User
      session: AuthSession
      subaccounts: Subaccount[]
      deviceSessionKeys: DeviceSessionKey[]
      accountDisabledReason: TransactionDisabledReason | null
      inviteCode?: string
    }
  | {
      isAuthenticated: false
      isMaybeAuthenticated: boolean
      user?: undefined
      session?: undefined
      subaccounts: []
      deviceSessionKeys: []
      accountDisabledReason?: undefined
      inviteCode?: undefined
    }

export const NO_AUTH: Auth = {
  isAuthenticated: false,
  isMaybeAuthenticated: false,
  subaccounts: [],
  deviceSessionKeys: [],
}

export type LinkableSocial = 'discord' | 'twitter'

export type RegisterSessionKeyParams =
  | {
      hasRegisteredKeys: true
      authKey: PrivateKeyAccount
      authTimestamp: string
      authSignature: Hash
      sessionPrivateKey: Hex
      sessionKeySecret: CryptoKey
      sessionKey: PrivateKeyAccount
    }
  | {
      hasRegisteredKeys: false
    }
