import Button from '@lyra/core/components/Button'
import Modal from '@lyra/core/components/Modal'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { useIntercom } from 'react-use-intercom'

type Props = {
  error: string
  onClose: () => void
}

export default function AuthErrorModal({ error, onClose }: Props) {
  const { show } = useIntercom()

  return (
    <Modal
      onClose={onClose}
      title="Something went wrong"
      subtitle="Your sign in attempt failed. Please check the error message below and try again. If the error persists, contact support."
    >
      <Section.XStack>
        <Notice message={error} status="error" width="100%" />
      </Section.XStack>
      <Section.YStack>
        <Button label="Contact Support" size="lg" isSolid color="green" onPress={show} />
        <Button label="Close" size="lg" onPress={onClose} />
      </Section.YStack>
    </Modal>
  )
}
