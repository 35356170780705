import { Check } from '@tamagui/lucide-icons'
import React from 'react'
import { useTheme } from 'tamagui'

import CheckboxFrame from './styles'

export type CheckboxProps = {
  isChecked: boolean
  isDisabled?: boolean
  onPress?: () => void
}

const Checkbox = ({ isChecked, isDisabled, onPress }: CheckboxProps) => {
  const theme = useTheme()
  return (
    <CheckboxFrame
      maxWidth={20}
      maxHeight={20}
      disabled={isDisabled}
      checked={isChecked}
      onPress={onPress}
    >
      {isChecked ? <Check color={theme.invertedText?.get()} size={16} /> : null}
    </CheckboxFrame>
  )
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
