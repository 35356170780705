import useIsMobile from '@lyra/core/hooks/useIsMobile'
import { X } from '@tamagui/lucide-icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dialog, StackProps, YStack } from 'tamagui'

import Button from '../Button'
import Section from '../Section'

const DESKTOP_MODAL_WIDTH = 420

export type ModalControlProps = {
  desktopWidth?: number | string
  disableClose?: boolean
} & (
  | {
      // RECOMMENDED!!
      // for modals that mount/re-mount when they are opened
      // best for reseting state when a modal closes
      onClose: () => void
      onChangeOpen?: undefined
      isOpen?: undefined
    }
  | {
      // for modals that mount before they are opened
      // best for persisting state when a modal closes
      isOpen: boolean
      onChangeOpen: (isOpen: boolean) => void
      onClose?: undefined
    }
)

export type ModalProps = {
  children: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  overflow?: StackProps['overflow']
} & ModalControlProps

type DialogSectionProps = {
  children: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  isOpen: boolean
  onChangeOpen: (isOpen: boolean) => void
  overflow?: StackProps['overflow']
} & Pick<ModalControlProps, 'disableClose'>

const DialogSection = ({
  title,
  subtitle,
  children,
  disableClose,
  onChangeOpen,
  overflow,
}: DialogSectionProps) => {
  const [opacity, setOpacity] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const isMobile = useIsMobile()
  useEffect(() => {
    if (isMobile) {
      return
    }
    // Animate in
    timeoutRef.current = setTimeout(() => {
      setOpacity(1)
    })

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const closeButton = !disableClose ? (
    <Button
      marginLeft="auto"
      icon={<X />}
      onPress={(e) => {
        e.stopPropagation()
        onChangeOpen(false)
      }}
    />
  ) : null

  return (
    <YStack
      opacity={opacity}
      backgroundColor="$appBg"
      animation="ease-regular"
      rowGap="$5"
      $desktop={{
        paddingVertical: '$4',
        paddingHorizontal: '$1',
      }}
      $mobile={{
        paddingVertical: '$3',
      }}
      overflow={overflow}
    >
      {title || subtitle || !disableClose ? (
        <Section.Header zIndex={1} title={title} subtitle={subtitle} rightContent={closeButton} />
      ) : null}
      {children}
    </YStack>
  )
}

export default function Modal({
  isOpen: isOpenInput,
  disableClose,
  desktopWidth,
  onChangeOpen,
  onClose,
  ...contentProps
}: ModalProps) {
  const handleChangeOpen = useCallback(
    (isOpen: boolean) => {
      if (disableClose) {
        return
      }
      if (onChangeOpen) {
        onChangeOpen(isOpen)
      } else if (!isOpen) {
        onClose()
      }
    },
    [disableClose, onChangeOpen, onClose]
  )

  // if onChangeOpen is defined, user is manually handling isOpen state
  // else user is relying on onClose to close modal
  const isOpen = onChangeOpen ? isOpenInput : true

  useEffect(() => {
    const handler = (e: MouseEvent) => e.stopPropagation()
    window.addEventListener('click', handler)
    return () => {
      window.removeEventListener('click', handler)
    }
  }, [])

  return (
    <Dialog open={isOpen} onOpenChange={handleChangeOpen} modal>
      <Dialog.Adapt
        when={
          // HACK @michaelxuwu terrible but compilation not picking up defined media queries
          'mobile' as unknown as undefined
        }
      >
        <Dialog.Sheet
          modal
          animation="ease-regular"
          dismissOnOverlayPress={!disableClose}
          snapPointsMode="fit"
          disableDrag
        >
          <Dialog.Sheet.Overlay
            animation="ease-regular"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
            backgroundColor="$overlay"
            backdropFilter="blur(2px)"
          />
          <Dialog.Sheet.Frame backgroundColor="$appBg">
            <DialogSection
              isOpen={isOpen}
              onChangeOpen={handleChangeOpen}
              disableClose={disableClose}
              {...contentProps}
            />
          </Dialog.Sheet.Frame>
        </Dialog.Sheet>
      </Dialog.Adapt>

      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          key="overlay"
          animation="ease-regular"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          backgroundColor="$overlay"
          backdropFilter="blur(2px)"
        />
        <Dialog.Content
          paddingVertical="$8"
          marginBottom="auto"
          width={desktopWidth ?? DESKTOP_MODAL_WIDTH}
          maxWidth={desktopWidth ?? DESKTOP_MODAL_WIDTH}
          key="content"
          animation="ease-regular"
          exitStyle={{ opacity: 0 }}
          maxHeight="100%"
          style={{ overflowY: 'auto' }}
        >
          {/* Prevent warning about dialog titles */}
          <Dialog.Title display="none" />
          <DialogSection
            isOpen={isOpen}
            onChangeOpen={handleChangeOpen}
            disableClose={disableClose}
            {...contentProps}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
