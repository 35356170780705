import { PageArgsMap, PageId, PagePathArgs, Pathname } from '@lyra/web/src/constants/pages'

import { QueryParam } from '../constants/query'

export const removeQueryAndHashFromPathname = (pathname: string) => {
  let cleanPathname = pathname.split('?')[0] // Split at the query parameter start and take the first part
  cleanPathname = cleanPathname.split('#')[0] // Split at the hash parameter start and take the first part
  return cleanPathname
}

const getOptionsPathname = (args: PageArgsMap[PageId.Options] = {}): string => {
  const market = args.marketId
  const params = new URLSearchParams()
  if (args.instrumentName) {
    // instrument field
    const isBuy = !!args.isBuy
    params.append(QueryParam.SelectedOptions, args.instrumentName + (isBuy ? '-B' : '-S'))
  }
  if (args.size) {
    // size field
    params.append(QueryParam.Size, args.size.toString())
  }
  if (args.limitPrice) {
    // limit price
    params.append(QueryParam.LimitPrice, args.limitPrice.toString())
  }
  const paramsStr = params.size ? `?${params}` : ''
  if (!market) {
    return `options${paramsStr}`.toLowerCase()
  } else {
    return `options/${market}${paramsStr}`.toLowerCase()
  }
}

const getPerpsPathname = (args: PageArgsMap[PageId.Perps] = {}): string => {
  const market = args.marketId
  const params = new URLSearchParams()
  if (args.size) {
    // size field
    params.append(QueryParam.Size, args.size.toString())
  }
  if (args.limitPrice) {
    // limit price
    params.append(QueryParam.LimitPrice, args.limitPrice.toString())
  }
  const paramsStr = params.size ? `?${params}` : ''
  if (!market) {
    return `perps${paramsStr}`.toLowerCase()
  } else {
    return `perps/${market}${paramsStr}`.toLowerCase()
  }
}

const getSpotPathname = (args: PageArgsMap[PageId.Spot] = {}): string => {
  const collateral = args.marketId
  const params = new URLSearchParams()
  if (args.size) {
    // size field
    params.append(QueryParam.Size, args.size.toString())
  }
  if (args.limitPrice) {
    // limit price
    params.append(QueryParam.LimitPrice, args.limitPrice.toString())
  }
  const paramsStr = params.size ? `?${params}` : ''
  if (!collateral) {
    return `spot${paramsStr}`.toLowerCase()
  } else {
    return `spot/${collateral}${paramsStr}`.toLowerCase()
  }
}

const getHistoryPathname = (args: PageArgsMap[PageId.History]) => {
  return 'history' + (args.tab ? `?tab=${args.tab?.toLowerCase()}` : '')
}

const getPagePathname = <T extends keyof PageArgsMap>(args: PagePathArgs<T>): string => {
  const page = args.page as PageId
  switch (page) {
    case PageId.Landing:
      return ''
    case PageId.CreateWallet:
      return 'wallet/create'
    case PageId.TermsOfUse:
      return 'terms-of-use'
    case PageId.PrivacyPolicy:
      return 'privacy-policy'
    case PageId.Options:
      return getOptionsPathname(args as PageArgsMap[PageId.Options])
    case PageId.Perps:
      return getPerpsPathname(args as PageArgsMap[PageId.Perps])
    case PageId.Spot:
      return getSpotPathname(args as PageArgsMap[PageId.Spot])
    case PageId.Portfolio:
      return 'portfolio'
    case PageId.Home:
      return 'home'
    case PageId.History:
      return getHistoryPathname(args as PageArgsMap[PageId.History])
    case PageId.RewardsHistory:
      return 'rewards/history'
    case PageId.Settings:
      return 'settings'
    case PageId.Developers:
      return 'developers'
    case PageId.Alpha:
      return 'alpha'
    case PageId.Stats:
      return 'stats'
    case PageId.Airdrop:
      return 'airdrop'
    case PageId.PointsLeaderboard:
      return 'leaderboard/points'
    case PageId.TradersLeaderboard:
      return 'leaderboard/traders'
    case PageId.ReferralsLeaderboard:
      return 'leaderboard/referrals'
    case PageId.User:
      return `user/${(args as PageArgsMap[PageId.User]).address}`.toLowerCase()
    case PageId.Earn:
      return 'earn'
    case PageId.Vault:
      return 'vault/' + (args as PageArgsMap[PageId.Vault]).id.toLowerCase()
    case PageId.Migration:
      return 'migration'
    case PageId.Rounds:
      return 'rounds'
    case PageId.Invites:
      return 'invites'
    case PageId.Borrow:
      return 'borrow'
    case PageId.LendCollateral:
      return 'lend/' + (args as PageArgsMap[PageId.LendCollateral]).collateral.toLowerCase()
    case PageId.Rfq:
      return 'rfq'
    case PageId.Balances:
      return 'balances'
    case PageId.Partners:
      return 'partners'
    case PageId.Positions:
      return 'positions'
    case PageId.Amberdata:
      return 'amberdata'
    case PageId.Trades:
      return 'trades'
  }
}

export const getPagePath = <T extends keyof PageArgsMap>(args: PagePathArgs<T>): Pathname => {
  const pathname = getPagePathname(args)
  return `/${pathname}`
}
