import { Stack, styled } from 'tamagui'

export default styled(Stack, {
  name: 'Checkbox',
  borderRadius: '$1',
  borderWidth: 1,
  borderColor: '$hairline',
  backgroundColor: '$hoverBg',
  minWidth: 20,
  minHeight: 20,
  width: 20,
  height: 20,
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  hoverStyle: {
    borderColor: '$redPrimary',
  },
  variants: {
    checked: {
      true: {
        backgroundColor: '$redPrimary',
        borderColor: '$redPrimary',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$disabledBg',
        borderColor: '$disabledText',
        hoverStyle: {
          borderColor: '$disabledText',
        },
        cursor: 'not-allowed',
      },
    },
  } as const,
  defaultVariants: {
    disabled: false,
  },
})
